import { Route, Routes  } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import { Suspense, lazy} from 'react';
import Loading from './Page/Loading/Loading';
const Login = lazy(() => import("./Auth/Login/Login"));
const EmailVerify = lazy(() => import("./Auth/EmailVerify/EmailVerify"));
const ResetPassword = lazy(() => import("./Auth/ResetPassword/ResetPassword"));
const AllInfluencer = lazy(() => import("./Component/AllInfluencer/AllInfluencer"));
const AddQuestions = lazy(() => import("./Component/AddQuestions/AddQuestions"));
const Users = lazy(() => import("./Component/Users/Users"));
const InfluencerDetails = lazy(() => import("./Component/InfluencerDetails/InfluencerDetails"));
const UsersDetails = lazy(() => import("./Component/UsersDetails/UsersDetails"));
const MembershipTiers = lazy(() => import("./Component/MembershipTiers/MembershipTiers"));
const Logout = lazy(() => import("./Page/Logout/Logout"));
const TestVerify = lazy(() => import('./Auth/EmailVerify/TestVerify'));
const Test = lazy(() => import('./Auth/AiCreated/Test'));

function App() {
 

  return (
    <div>
      <ToastContainer />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/emailVerify/:token" element={<TestVerify />}></Route>
          <Route path="/emailVerify" element={<TestVerify />}></Route>
          <Route path="/AICreated" element={<Test/>}></Route>

          {/* <Route path="/TestVerify" element={<TestVerify />}></Route> */}
          <Route
            path="/resetPassword/:id/:token"
            element={<ResetPassword />}
          ></Route>
          <Route path="/allInfluencer" element={<AllInfluencer />}></Route>
          <Route path="/allUsers" element={<Users />}></Route>
          <Route
            path="/influencerDetails/:id"
            element={<InfluencerDetails />}
          ></Route>
          <Route path="/usersDetails/:id" element={<UsersDetails />}></Route>
          <Route path="/membershipTiers" element={<MembershipTiers />}></Route>
          <Route path="/addQuestions" element={<AddQuestions />}></Route>
          <Route path="/logout" element={<Logout />}></Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
